import React from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import Footer2 from '../Element/Footer';
import PageTitle from './../Layout/PageTitle';

const PrivacyPolicy = () => {
    return (
        <>
            <Header />
            <div className="page-content bg-white">
                <div className="overlay-primary" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>
                    <PageTitle motherMenu="Privacy Policy" activeMenu="Privacy Policy" />
                </div>
                <div className="content-block">
                    <div className="section-full content-inner-2">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-head text-black m-t30">
                                        <h3 className="title">Privacy Policy & SMS Terms</h3>
                                    </div>

                                    <div className="dlab-content">
                                        {/* Introduction */}
                                        <div className="m-b30">
                                            <p className="m-b10">Last Updated: January 9, 2025</p>
                                            <p>Welcome to devdisruptors's Privacy Policy. This document outlines how we collect, use, and protect your information.</p>
                                        </div>

                                        {/* SMS Privacy Statement */}
                                        <div className="m-b30">
                                            <h4 className="m-b10">SMS Privacy Statement</h4>
                                            <p><strong>Important Notice:</strong> SMS opt-in consent and phone numbers for the purpose of SMS are not being shared with any third parties or affiliates.</p>
                                        </div>

                                        {/* SMS Terms & Conditions */}
                                        <div className="m-b30">
                                            <h4 className="m-b10">SMS Terms & Conditions</h4>
                                            
                                            <h5 className="m-b10">SMS Consent Communication</h5>
                                            <p>The information (Phone Numbers) obtained as part of the SMS consent process will not be shared with third parties for marketing purposes.</p>

                                            <h5 className="m-b10">Types of SMS Communications</h5>
                                            <p>If you have consented to receive text messages from devdisruptors, you may receive messages related to:</p>
                                            <ul className="list-check primary m-b30">
                                                <li>Project updates and status changes</li>
                                                <li>Follow-up communications about your inquiries</li>
                                                <li>Important service notifications</li>
                                                <li>Promotional offers (where applicable)</li>
                                            </ul>

                                            <h5 className="m-b10">Message Frequency</h5>
                                            <p>Message frequency may vary. You may receive up to 4 SMS messages per week related to your projects and account status.</p>

                                            <h5 className="m-b10">Potential Fees for SMS Messaging</h5>
                                            <p>Please note that standard message and data rates may apply, depending on your carrier's pricing plan. These fees may vary if the message is sent domestically or internationally.</p>

                                            <h5 className="m-b10">Opt-In Methods</h5>
                                            <p>You may opt-in to receive SMS messages from devdisruptors through:</p>
                                            <ul className="list-check primary m-b30">
                                                <li>Checking the SMS consent box on our website forms</li>
                                                <li>Providing explicit consent during project discussions</li>
                                                <li>Submitting a written consent form</li>
                                            </ul>

                                            <h5 className="m-b10">Opt-Out Method</h5>
                                            <p>You can opt-out of receiving SMS messages at any time by:</p>
                                            <ul className="list-check primary m-b30">
                                                <li>Replying "STOP" to any SMS message you receive</li>
                                                <li>Contacting our support team directly</li>
                                            </ul>

                                            <h5 className="m-b10">Help & Support</h5>
                                            <p>If you need assistance with SMS communications:</p>
                                            <ul className="list-check primary m-b30">
                                                <li>Reply "HELP" to any SMS message</li>
                                                <li>Email us at contact@devdisruptors.com</li>
                                            </ul>

                                            <h5 className="m-b10">Standard Messaging Disclosures</h5>
                                            <ul className="list-check primary m-b30">
                                                <li>Message and data rates may apply</li>
                                                <li>You can opt out at any time by texting "STOP"</li>
                                                <li>For assistance, text "HELP" or contact our support team</li>
                                                <li>Message frequency may vary</li>
                                            </ul>
                                        </div>

                                        {/* General Privacy Information */}
                                        <div className="m-b30">
                                            <h4 className="m-b10">Information We Collect</h4>
                                            <p>We collect information that you provide directly to us, including:</p>
                                            <ul className="list-check primary m-b30">
                                                <li>Name and contact information</li>
                                                <li>Email address</li>
                                                <li>Phone number</li>
                                                <li>Project requirements and details</li>
                                            </ul>
                                        </div>

                                        <div className="m-b30">
                                            <h4 className="m-b10">Changes to This Policy</h4>
                                            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.</p>
                                        </div>

                                        <div className="m-b30">
                                            <h4 className="m-b10">Contact Us</h4>
                                            <p>If you have any questions about this Privacy Policy, please contact us:</p>
                                            <ul className="list-check primary m-b30">
                                                <li>Email: contact@devdisruptors.com</li>
                                                <li>Address: DevDisruptors LLC, 563 High Street Suite B PMB 1137 Burlington, NJ 08016 USA</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="section-full overlay-primary-dark bg-img-fix"
                style={{
                    backgroundImage:
                        'linear-gradient(140deg, #EADEDB 20%, #BC70A4 50%, #077F84 75%)',
                    zIndex: 5
                }}
            >
                <Footer2 />
                <div className="row">
                    <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ backgroundColor: '#6CC000', zIndex: 10 }}
                    >
                        {' '}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default PrivacyPolicy;