import React, {Fragment, useRef, useState} from 'react';
import emailjs from '@emailjs/browser';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

const FormStyle2=()=>{
	const form = useRef();
	const [smsConsent, setSmsConsent] = useState(false);

	const sendEmail = (e) => {
		e.preventDefault();
		debugger
	
		emailjs.sendForm('service_1qj3vwd', 'template_igul9qr', form.current, 'ZLeR-68dSKjSqONrJ')
		  .then((result) => {
			  console.log(result.text);
            NotificationManager.success('Email Sent Successfully');
		  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
		  setSmsConsent(false); // Reset checkbox state
	  };

		return(
			<Fragment>
				<div className="container">
					<div className="row">
					
						<div className="col-lg-12 col-md-12 m-b30">
							<form className="inquiry-form  dzForm" 
							//  action="script/contact.php"
							ref={form} onSubmit={sendEmail}
							 >
								<div className="dzFormMsg"></div>
								<h3 className="box-title m-t30 m-b40">Let's Convert Your Idea into Reality <span className="bg-primary"></span></h3>
								{/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the</p> */}
								<div className="row">
									<div className="col-lg-6">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-user text-primary"></i></span>
												<input name="Name" type="text" required className="form-control" placeholder="Full Name" />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
												<input name="Phone" type="text" required className="form-control" placeholder="Phone" />
											</div>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-email text-primary"></i></span>
												<input name="Email" type="email" className="form-control" required placeholder="Email" />
											</div>
										</div>
									</div>
									{/* <div className="col-lg-6">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-check-box text-primary"></i></span>
												<input name="dzOther[Subject]" type="text" required className="form-control" placeholder="Upload File" />
											</div>
										</div>
									</div>
									<div className="col-lg-6">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-file text-primary"></i></span>
												<input name="dzOther[Subject]" type="text" required className="form-control" placeholder="Upload File" />
											</div>
										</div>
									</div> */}
									<div className="col-lg-12">
										<div className="form-group">
											<div className="input-group">
												<span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
												<textarea name="Message" rows="4" className="form-control" required placeholder="Tell us about your project or idea"></textarea>
											</div>
										</div>
									</div>
									{/* <div className="col-lg-4">
										<div className="form-group">
											<div className="input-group">
												<div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
												<input className="form-control d-none" data-recaptcha="true" required data-error="Please complete the Captcha" />
											</div>
										</div>
									</div> */}
									<div className="col-lg-12">
										<div className="form-group">
											<div style={{ display: 'flex', alignItems: 'flex-start', padding: '8px' }}>
												<input 
													type="checkbox" 
													id="smsConsent" 
													name="smsConsent"
													checked={smsConsent}
													onChange={(e) => setSmsConsent(e.target.checked)}
													style={{ marginRight: '8px', marginTop: '4px' }}
												/>
												<label htmlFor="smsConsent" style={{ fontSize: '14px', color: '#666', cursor: 'pointer' }}>
												By checking this box, you agree to receive SMS text messages from DevDisruptors. Reply STOP to opt out at any time. Reply HELP for Customer Care Contact Information. Messages and data rates may apply. Message frequency will vary.
												</label>
											</div>
										</div>
									</div>
									<div className="col-lg-5">
										<button name="submit" type="submit" value="Submit" className="site-button button-lg"  style={{borderRadius:"10px"}}> <span>Send Email</span> </button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Fragment>
		)
}
export default FormStyle2;